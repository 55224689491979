import { ColorThemeTokens } from "../palette";

export const FontFamilies = {
  ttRounds: "Ttrounds" as const,
  sfPro: "SFPro" as const,
  teko: "Teko" as const,
  poppins: "Poppins" as const,
};

export type TextVariantToken =
  | "h4"
  | "intro"
  | "subtitle"
  | "body"
  | "bodyTitle"
  | "bodyLarge"
  | "bodySmall"
  | "imageTag"
  | "hero"
  | "header"
  | "labelSmall"
  | "labelMedium"
  | "bodySmall"
  | "buttonLarge"
  | "buttonMedium"
  | "buttonSmall";

export type TextVariant = {
  fontFamily: ValueOf<typeof FontFamilies>;
  fontSize: number | number[];
  lineHeight: number | string;
  fontWeight: number;
  color: ColorThemeTokens;
  htmlElement: "h1" | "h2" | "h3" | "h4" | "p" | "span";
  marginBlock: 0;
};

export const textVariants: Record<TextVariantToken, TextVariant> = {
  h4: {
    fontFamily: FontFamilies.teko,
    fontSize: [32, null, 60],
    fontWeight: 500,
    lineHeight: "76%",
    color: "contentContrastHigh",
    htmlElement: "h4",
    marginBlock: 0,
  },
  intro: {
    fontFamily: FontFamilies.poppins,
    fontSize: [16, null, 22],
    fontWeight: 400,
    lineHeight: "160%",
    color: "contentContrastHigh",
    htmlElement: "p",
    marginBlock: 0,
  },
  subtitle: {
    fontFamily: FontFamilies.poppins,
    fontSize: [16, null, 22],
    fontWeight: 500,
    lineHeight: "160%",
    color: "contentContrastHigh",
    htmlElement: "p",
    marginBlock: 0,
  },
  body: {
    fontFamily: FontFamilies.poppins,
    fontSize: [14, 14, 14],
    fontWeight: 400,
    lineHeight: "160%",
    color: "contentContrastHigh",
    htmlElement: "p",
    marginBlock: 0,
  },
  bodyTitle: {
    fontFamily: FontFamilies.poppins,
    fontSize: [14, 14, 14],
    fontWeight: 500,
    lineHeight: "160%",
    color: "contentContrastHigh",
    htmlElement: "p",
    marginBlock: 0,
  },
  bodyLarge: {
    fontFamily: FontFamilies.poppins,
    fontSize: [16, 16, 16],
    fontWeight: 400,
    lineHeight: "160%",
    color: "contentContrastHigh",
    htmlElement: "p",
    marginBlock: 0,
  },
  bodySmall: {
    fontFamily: FontFamilies.poppins,
    fontSize: [12, 12, 12],
    fontWeight: 400,
    lineHeight: "160%",
    color: "contentContrastHigh",
    htmlElement: "p",
    marginBlock: 0,
  },
  imageTag: {
    fontFamily: FontFamilies.poppins,
    fontSize: [10, null, 12],
    fontWeight: 500,
    lineHeight: "110%",
    color: "contentContrastHigh",
    htmlElement: "span",
    marginBlock: 0,
  },
  hero: {
    fontFamily: FontFamilies.poppins,
    fontSize: [24, null, 30],
    fontWeight: 500,
    lineHeight: "140%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
  header: {
    fontFamily: FontFamilies.poppins,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "140%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
  labelSmall: {
    fontFamily: FontFamilies.poppins,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "130%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
  labelMedium: {
    fontFamily: FontFamilies.poppins,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "150%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
  buttonLarge: {
    fontFamily: FontFamilies.poppins,
    fontSize: [16, 16, 16],
    fontWeight: 500,
    lineHeight: "150%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
  buttonMedium: {
    fontFamily: FontFamilies.poppins,
    fontSize: [14, 14, 14],
    fontWeight: 500,
    lineHeight: "130%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
  buttonSmall: {
    fontFamily: FontFamilies.poppins,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "120%",
    htmlElement: "span",
    marginBlock: 0,
    color: "contentContrastHigh",
  },
};
