/* eslint-disable no-param-reassign */
import axios from "axios";
import { action, thunk, Thunk, Action } from "easy-peasy";
import Cookies from "js-cookie";

import { createAPIClient, KIC_API } from "../../src/api/index";
import { updateBUMPStoredConsent } from "../../src/components/dashboard/masterclassesPage/BUMPMedicalCheck";
import { getTokenExpiryEpoch, hasEpochExpired } from "../../src/utils/utils";
import initialState from "./auth.state";
import * as monitoring from "../../src/utils/monitoring";
import {
  getAuthTokens,
  TOKEN_EXPIRATION_BUFFER_SECONDS,
} from "../../src/api/auth";
import { EasyAuthModel } from "./auth.store";
import { StoreModel } from "..";
import { EasyUserModel } from "../admin/users/admin.users.store";

type AuthModelThunk<Payload = void> = Thunk<
  EasyUserModel,
  Payload,
  any,
  StoreModel
>;

export type AuthActions = {
  checkTokenExpiration: AuthModelThunk;
  getUpdatedToken: AuthModelThunk;
  updateAuthData: Action<EasyAuthModel, any>;

  fetchSubscriptions: AuthModelThunk;
  getSubscriptionData: AuthModelThunk;
  setSubscriptions: Action<EasyAuthModel, any>;
  setSubscriptionData: Action<EasyAuthModel, any>;
  updatedSubscriptionData: Action<EasyAuthModel, any>;
  setSubscriptionStatus: Action<EasyAuthModel, any>;

  setUnauthenticatedGlobals: Action<EasyAuthModel, any>;
  fetchUnauthenticatedGlobals: AuthModelThunk;

  signUp: AuthModelThunk;
  logIn: AuthModelThunk<{ email: string; password: string }>;
  logInAuthCode: AuthModelThunk<{ code: string }>;
  logInFacebook: AuthModelThunk<{
    access_token: string;
    request_uri: string;
  }>;
  logInApple: AuthModelThunk<{ code: string; request_uri: string }>;
  logInGoogle: AuthModelThunk<{ access_token: string; request_uri: string }>;
  logOut: Action<EasyAuthModel, void>;
  requestPasswordReset: AuthModelThunk;
  resetPassword: AuthModelThunk;

  updateCardDetails: AuthModelThunk<Record<string, unknown>>;
};

const actions = {
  checkTokenExpiration: thunk<EasyAuthModel>(
    async (actions, payload, { getState }) => {
      const { token, tokenExpiration, refreshTokenExpiration } =
        getState().authData;

      if (!hasEpochExpired(tokenExpiration)) {
        return { didTokenExpire: false, isValidUser: true, token };
      }

      if (hasEpochExpired(refreshTokenExpiration)) {
        return { didTokenExpire: true, isValidUser: false, token };
      }

      if (hasEpochExpired(tokenExpiration)) {
        const { auth, error } = await actions.getUpdatedToken();
        if (error) {
          return { didTokenExpire: true, isValidUser: false };
        }
        return { didTokenExpire: true, isValidUser: true, token: auth };
      }
    },
  ),
  getUpdatedToken: thunk<EasyAuthModel>(async (actions) => {
    try {
      const tokens = await getAuthTokens();

      actions.updateAuthData({
        token: tokens.auth,
        tokenExpiration: getTokenExpiryEpoch(
          tokens.auth,
          TOKEN_EXPIRATION_BUFFER_SECONDS,
        ),
        refreshToken: tokens.refresh,
        refreshTokenExpiration: getTokenExpiryEpoch(tokens.refresh),
      });

      return tokens;
    } catch (error) {
      console.error("getUpdatedToken error", error);
      return error?.response?.data ?? { error };
    }
  }),
  fetchSubscriptions: thunk<EasyAuthModel, any>(
    async (actions, { coupon } = {}) => {
      try {
        const apiClient = createAPIClient();
        const plans = await apiClient.plans.list(coupon);
        actions.setSubscriptions(plans);
      } catch (err) {
        return err?.response?.data;
      }
    },
  ),
  fetchUnauthenticatedGlobals: thunk<EasyAuthModel, any | void>(
    // eslint-disable-next-line default-param-last
    async (actions, payload = {}) => {
      try {
        const { serverSide } = payload;

        const response = await axios.get(
          `${KIC_API}/api/v1/globals/unauthenticated`,
          { timeout: 5000 },
        );

        !serverSide && actions.setUnauthenticatedGlobals(response.data);

        return response.data;
      } catch (error) {
        if (error.code === "ETIMEDOUT" || error.message.includes("timeout")) {
          return { error: "timeout" };
        }
        return error.response.data;
      }
    },
  ),
  getSubscriptionData: thunk<EasyAuthModel>(
    async (actions, payload, { getState }) => {
      await actions.checkTokenExpiration();

      const { token } = getState().authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/usersubscriptions/data`,
          { headers },
        );

        actions.setSubscriptionData(response.data);

        return response.data;
      } catch (error) {
        console.error("getSubscriptionData error", error);
        return error.response.data;
      }
    },
  ),

  logIn: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/login`,
        payload,
      );

      const { id, token, refreshToken } = response.data;

      const tokenExpiration = await getTokenExpiryEpoch(
        token,
        TOKEN_EXPIRATION_BUFFER_SECONDS,
      );
      const refreshTokenExpiration = await getTokenExpiryEpoch(refreshToken);

      const authData = {
        tokenExpiration,
        refreshTokenExpiration,
        id,
        token,
        refreshToken,
      };

      Cookies.set("kic_token", token, {
        expires: new Date(tokenExpiration * 1000),
      });
      Cookies.set("kic_refresh", refreshToken, {
        expires: new Date(refreshTokenExpiration * 1000),
      });

      actions.updateAuthData(authData);

      await actions.getSubscriptionData();
      await actions.fetchUnauthenticatedGlobals();

      return response.data;
    } catch (error) {
      return error.response?.data;
    }
  }),
  logInAuthCode: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/auth-code-login-end`,
        payload,
      );

      const { id, token, refreshToken } = response.data;

      const tokenExpiration = await getTokenExpiryEpoch(
        token,
        TOKEN_EXPIRATION_BUFFER_SECONDS,
      );
      const refreshTokenExpiration = await getTokenExpiryEpoch(refreshToken);

      const authData = {
        tokenExpiration,
        refreshTokenExpiration,
        id,
        token,
        refreshToken,
      };

      Cookies.set("kic_token", token, {
        expires: new Date(tokenExpiration * 1000),
      });
      Cookies.set("kic_refresh", refreshToken, {
        expires: new Date(refreshTokenExpiration * 1000),
      });

      actions.updateAuthData(authData);

      await actions.getSubscriptionData();
      await actions.fetchUnauthenticatedGlobals();

      return response.data;
    } catch (error) {
      return error.response?.data;
    }
  }),
  logInFacebook: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/facebook`,
        payload,
      );

      const { id, token, refreshToken } = response.data;

      const tokenExpiration = await getTokenExpiryEpoch(
        token,
        TOKEN_EXPIRATION_BUFFER_SECONDS,
      );
      const refreshTokenExpiration = await getTokenExpiryEpoch(refreshToken);

      Cookies.set("kic_token", token, {
        expires: new Date(tokenExpiration * 1000),
      });
      Cookies.set("kic_refresh", refreshToken, {
        expires: new Date(refreshTokenExpiration * 1000),
      });

      actions.updateAuthData({
        id,
        token,
        tokenExpiration,
        refreshToken,
        refreshTokenExpiration,
      });

      await actions.getSubscriptionData();
      await actions.fetchUnauthenticatedGlobals();

      return response.data;
    } catch (error) {
      console.error("logInFacebook error", error);
      return error.response.data;
    }
  }),
  logInApple: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/apple_signin`,
        payload,
      );

      const { id, token, refreshToken } = response.data;

      const tokenExpiration = await getTokenExpiryEpoch(
        token,
        TOKEN_EXPIRATION_BUFFER_SECONDS,
      );
      const refreshTokenExpiration = await getTokenExpiryEpoch(refreshToken);

      Cookies.set("kic_token", token, {
        expires: new Date(tokenExpiration * 1000),
      });
      Cookies.set("kic_refresh", refreshToken, {
        expires: new Date(refreshTokenExpiration * 1000),
      });

      actions.updateAuthData({
        id,
        token,
        tokenExpiration,
        refreshToken,
        refreshTokenExpiration,
      });

      await actions.getSubscriptionData();
      await actions.fetchUnauthenticatedGlobals();

      return response.data;
    } catch (error) {
      console.error("logInApple error", error);
      return { error: error.response.data };
    }
  }),
  logInGoogle: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v3/google/login`,
        payload,
      );

      const { id, token, refreshToken } = response.data;

      const tokenExpiration = await getTokenExpiryEpoch(
        token,
        TOKEN_EXPIRATION_BUFFER_SECONDS,
      );
      const refreshTokenExpiration = await getTokenExpiryEpoch(refreshToken);

      Cookies.set("kic_token", token, {
        expires: new Date(tokenExpiration * 1000),
      });
      Cookies.set("kic_refresh", refreshToken, {
        expires: new Date(refreshTokenExpiration * 1000),
      });

      actions.updateAuthData({
        id,
        token,
        tokenExpiration,
        refreshToken,
        refreshTokenExpiration,
      });

      await actions.getSubscriptionData();
      await actions.fetchUnauthenticatedGlobals();

      return response.data;
    } catch (error) {
      console.error("logInGoogle error", error);
      monitoring.notifyError(error);
      return { error: error.response.data };
    }
  }),
  logOut: action<EasyAuthModel, void | any>((state, payload) => {
    updateBUMPStoredConsent({ prenatal: false, postnatal: false });
    Cookies.remove("kic_token");
    Cookies.remove("kic_refresh");

    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }

    monitoring.logEvent({ eventName: "LogoutComplete", ...payload });
  }),
  requestPasswordReset: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/request_reset_password`,
        payload,
      );

      return response;
    } catch (error) {
      return error.response;
    }
  }),
  resetPassword: thunk<EasyAuthModel, any>(async (actions, payload) => {
    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/reset_password`,
        payload,
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }),
  setSubscriptions: action<EasyAuthModel, any>((state, payload) => {
    state.subscriptions = payload?.map(({ ...plan }) => ({
      ...plan,
    }));
  }),
  setSubscriptionData: action<EasyAuthModel, any>((state, payload) => {
    state.subscriptionData = payload;
  }),
  setSubscriptionStatus: action<EasyAuthModel, any>((state, payload) => {
    state.subscriptionStatus = payload;
  }),
  setUnauthenticatedGlobals: action<EasyAuthModel, any>((state, payload) => {
    state.unauthGlobals = payload;
  }),
  signUp: thunk<EasyAuthModel, any>(async (actions, payload) => {
    const { firstName, lastName, agreedToCommunications, ...credentials } =
      payload;

    try {
      const response = await axios.post(
        `${KIC_API}/api/v2/users/register`,
        credentials,
      );

      const { id, token, refreshToken } = response.data;

      const tokenExpiration = getTokenExpiryEpoch(
        token,
        TOKEN_EXPIRATION_BUFFER_SECONDS,
      );
      const refreshTokenExpiration = getTokenExpiryEpoch(refreshToken);

      const authData = {
        id,
        token,
        tokenExpiration,
        refreshToken,
        refreshTokenExpiration,
      };

      Cookies.set("kic_token", token, {
        expires: new Date(tokenExpiration * 1000),
      });
      Cookies.set("kic_refresh", refreshToken, {
        expires: new Date(refreshTokenExpiration * 1000),
      });

      await actions.fetchUnauthenticatedGlobals();
      actions.updateAuthData(authData);

      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return { error };
    }
  }),
  updateAuthData: action<EasyAuthModel, any>((state, payload) => {
    state.authData = { ...state.authData, ...payload };
  }),
  updateCardDetails: thunk<EasyAuthModel, any>(
    async (actions, payload, { getState }) => {
      await actions.checkTokenExpiration();

      const { token } = getState().authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/stripe/customer/payment_source`,
          payload,
          { headers },
        );

        return response.data;
      } catch (error) {
        console.error("updateCardDetails error", error);
        return error.response.data;
      }
    },
  ),
  updatedSubscriptionData: action<EasyAuthModel, any>((state, payload) => {
    state.subscriptionData = { ...state.subscriptionData, ...payload };
  }),
};

export default actions;
