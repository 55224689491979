import { ColorThemeMap, ThemeColorMode } from "./palette/palette";

type Breakpoints = {
  phone: any;
  tablet: any;
  laptop: any;
  desktop: any;
  default: any;
};
export type Theme = ReturnType<typeof createTheme>;

export type LegacyTheme = Record<string, any>;

// https://styled-system.com/theme-specification#key-reference
export const createTheme = (themeColorMode: ThemeColorMode) => {
  const breakpoints: Breakpoints = ["40em", "52em", "64em", "80em"] as any;
  breakpoints.phone = breakpoints[0];
  breakpoints.tablet = breakpoints[1];
  breakpoints.laptop = breakpoints[2];
  breakpoints.desktop = breakpoints[3];

  return {
    breakpoints,
    colors: ColorThemeMap[themeColorMode],
    space: {
      "none": 0,
      "xxxs": 2,
      "xxs": 4,
      "xs": 8,
      "sm": 16,
      "md": 32,
      "lg": 64,
      "xl": 128,
      "xxl": 256,
      "xxxl": 512,
      "-xxxs": -2,
      "-xxs": -4,
      "-xs": -8,
      "-sm": -16,
      "-md": -32,
      "-lg": -64,
      "-xl": -128,
      "-xxl": -256,
      "-xxxl": -512,
    },
    borderWidths: {
      none: 0,
      sm: 1,
      md: 2,
    },
    radii: {
      none: 0,
      xs: 4,
      sm: 8,
      md: 12,
      lg: 20,
      xl: 32,
      full: 100,
    },
    shadows: {
      sm: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
      smHeavy: "2px 3px 2px 0px rgba(0, 0, 0, .2)",
      mdLight: "0 10px 40px rgba(0, 0, 0, .1)",
      md: "0 10px 40px rgba(0, 0, 0, .125)",
      mdHeavy: "0 10px 40px rgba(0, 0, 0, .2)",
    },
    iconSize: {
      ixxl: 120,
      ixl: 64,
      ilg: 32,
      imd: 24,
      ism: 18,
      ixs: 14,
      ixxs: 8,
    },
  };
};
