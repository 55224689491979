import React, { useMemo, memo } from "react";
import Lottie from "react-lottie";

import whiteSpinner from "../../../public/static/lottie/spinner-white.json";
import tealSpinner from "../../../public/static/lottie/spinner-teal.json";
import contrastHighSpinner from "../../../public/static/lottie/spinner-contrast-high.json";

import { Box, BoxProps } from "../Box";

type LoadingSpinnerSize = "small" | "large";

interface LoadingSpinnerProps extends BoxProps {
  variant?: "spinner" | "dots";
  color?: "backgroundBrand" | "white" | "contentContrastHigh";
  size?: LoadingSpinnerSize;
}

const SizesMap: Record<LoadingSpinnerSize, { width: number; height: number }> =
  {
    small: { height: 24, width: 24 },
    large: { height: 96, width: 96 },
  };

const _LoadingIndicator: React.FC<LoadingSpinnerProps> = ({
  variant = "spinner",
  color = "backgroundBrand",
  size = "small",
  ...props
}) => {
  const animationData = useMemo(() => {
    if (variant === "spinner") {
      if (color === "backgroundBrand") {
        return tealSpinner;
      }
      if (color === "contentContrastHigh") {
        return contrastHighSpinner;
      } else {
        return whiteSpinner;
      }
    }

    throw Error("Dots not implemented!");
  }, [color]);

  return (
    <Box {...props}>
      <Lottie
        options={{
          animationData,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        {...SizesMap[size]}
      />
    </Box>
  );
};

export const LoadingIndicator = memo(_LoadingIndicator);
