import { SendRequest } from "../makeSendRequest";

type Answer = {
  aid: string;
  answer: string;
  followUpQuestion?: Question;
};

export type Question = {
  qid: string;
  question: string;
  answers: Answer[];
};

export type CancellationSurvey = {
  image: string;
  header: string;
  supporting: string;
  usps: string[];
  cta: string;
  question: Question;
};

export const createCancellationSurveyApi = (sendRequest: SendRequest) => ({
  getCancellationSurvey: async () => {
    const response = await sendRequest<CancellationSurvey>({
      method: "get",
      path: "cancellation-survey",
      version: "v3",
    });

    return response;
  },
});
