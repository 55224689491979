import React, { SVGProps } from "react";
import { ThemeValue } from "styled-system";

import { Theme, useTheme } from "../ui";

import Apple from "./Apple";
import BalanceScale from "./BalanceScale";
import Bars from "./Bars";
import Bench from "./Bench";
import Book from "./Book";
import Box from "./Box";
import BoxingGlove from "./BoxingGlove";
import BreadSlice from "./BreadSlice";
import Briefcase from "./Briefcase";
import Bullseye from "./Bullseye";
import Calendar from "./Calendar";
import CalendarWeek from "./CalendarWeek";
import CaretDown from "./CaretDown";
import CaretUp from "./CaretUp";
import Carrot from "./Carrot";
import Check from "./Check";
import CheckCircle from "./CheckCircle";
import CheeseSwiss from "./CheeseSwiss";
import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";
import ClipboardList from "./ClipboardList";
import Clock from "./Clock";
import CloudUpload from "./CloudUpload";
import Cog from "./Cog";
import Columns from "./Columns";
import Comments from "./Comments";
import Contract from "./Contract";
import Copy from "./Copy";
import { CloseIcon } from "./CloseIcon";
import Cosmopolitan from "./Cosmopolitan";
import Coupon from "./Coupon";
import Dinner from "./Dinner";
import Download from "./Download";
import DragHandle from "./DragHandle";
import Droplets from "./Droplets";
import DrumstickBite from "./DrumstickBite";
import Dumbbell from "./Dumbbell";
import DumbbellAlt from "./DumbbellAlt";
import Dumbbells from "./Dumbbells";
import Elle from "./Elle";
import EllipsisV from "./EllipsisV";
import Email from "./Email";
import EquipmentFree from "./EquipmentFree";
import ExclamationTriangle from "./ExclamationTriangle";
import Eye from "./Eye";
import FacebookCircle from "./FacebookCircle";
import FacebookSquare from "./FacebookSquare";
import FileInvoiceDollar from "./FileInvoiceDollar";
import Fish from "./Fish";
import FishAlt from "./FishAlt";
import Fridge from "./Fridge";
import Globe from "./Globe";
import Hashtag from "./Hashtag";
import HealthyFoood from "./HealthyFood";
import Heartbeat from "./Heartbeat";
import HeartRate from "./HeartRate";
import IdCard from "./IdCard";
import Instagram from "./Instagram";
import Kettlebell from "./Kettlebell";
import KICIcon from "./KICIcon";
import Leaf from "./Leaf";
import Link from "./Link";
import Lock from "./Lock";
import MapPin from "./MapPin";
import MedBall from "./MedBall";
import Meditate from "./Meditate";
import Microphone from "./Microphone";
import Minus from "./Minus";
import MoneyBill from "./MoneyBill";
import Music from "./Music";
import Pen from "./Pen";
import PenFancy from "./PenFancy";
import PhotoVideo from "./PhotoVideo";
import Pilates from "./Pilates";
import Plus from "./Plus";
import Popsugar from "./Popsugar";
import ResistanceBand from "./ResistanceBand";
import Running from "./Running";
import RunningAToB from "./RunningAToB";
import Search from "./Search";
import Seedling from "./Seedling";
import Shoe from "./Shoe";
import ShoppingBag from "./ShoppingBag";
import SkippingRope from "./SkippingRope";
import Sliders from "./Sliders";
import Spa from "./Spa";
import Star from "./Star";
import StarFull from "./StarFull";
import StarHalf from "./StarHalf";
import Stopwatch from "./Stopwatch";
import Subscription from "./Subscription";
import SwissBall from "./SwissBall";
import Sync from "./Sync";
import Tags from "./Tags";
import Time from "./Time";
import Times from "./Times";
import Trash from "./Trash";
import Treadmill from "./Treadmill";
import Trophey from "./Trophey";
import User from "./User";
import Users from "./Users";
import UserTag from "./UserTag";
import Utensils from "./Utensils";
import UtensilsOutline from "./UtensilsOutline";
import Walking from "./Walking";
import WalkingAlt from "./WalkingAlt";
import Whistle from "./Whistle";
import WomensHealth from "./WomensHealth";
import Yoga from "./Yoga";
import YogaBlock from "./YogaBlock";
import Youtube from "./Youtube";
import FoamRoller from "./FoamRoller";
import Pillow from "./Pillow";
import PilatesBall from "./PilatesBall";
import ArrowRight from "./ArrowRight";
import AppStoreButton from "./AppStoreButton";
import PlayStoreButton from "./PlayStoreButton";
import AppStoreButtonFilled from "./AppStoreButtonFilled";
import PlayStoreButtonFilled from "./PlayStoreButtonFilled";
import AnkleWeights from "./AnkleWeights";
import MiniBand from "./MiniBand";
import PilatesChair from "./PilatesChair";
import Google from "./Google";
import Firebase from "./Firebase";
import Home from "./Home";
import Facebook from "./Facebook";
import EyeHidden from "./EyeHidden";
import CaretRightOutline from "./CaretRightOutline";
import CaretDownOutline from "./CaretDownOutline";
import CaretLeftOutline from "./CaretLeftOutline";
import KicLogo from "./KicLogo";
import UserOutline from "./UserOutline";
import Tick from "./Tick";
import { AndroidIcon } from "./Android";

const Icons = {
  "drag-handle": DragHandle,
  "dumbbell-alt": DumbbellAlt,
  "walking-alt": WalkingAlt,
  "leaf": Leaf,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "dumbbell": Dumbbell,
  "dumbbells": Dumbbells,
  "bread-slice": BreadSlice,
  "utensils": Utensils,
  "apple": Apple,
  "spa": Spa,
  "check-circle": CheckCircle,
  "star": Star,
  "stopwatch": Stopwatch,
  "microphone": Microphone,
  "comments": Comments,
  "running": Running,
  "walking": Walking,
  "plus": Plus,
  "minus": Minus,
  "contract": Contract,
  "coupon": Coupon,
  "times": Times,
  "user": User,
  "exclamation-triangle": ExclamationTriangle,
  "columns": Columns,
  "shopping-bag": ShoppingBag,
  "book": Book,
  "id-card": IdCard,
  "photo-video": PhotoVideo,
  "kic-icon": KICIcon,
  "user-tag": UserTag,
  "briefcase": Briefcase,
  "subscription": Subscription,
  "money-bill": MoneyBill,
  "pen-fancy": PenFancy,
  "trophey": Trophey,
  "carrot": Carrot,
  "meditate": Meditate,
  "calendar-week": CalendarWeek,
  "heartbeat": Heartbeat,
  "tags": Tags,
  "cog": Cog,
  "users": Users,
  "treadmill": Treadmill,
  "balance-scale": BalanceScale,
  "clipboard-list": ClipboardList,
  "bullseye": Bullseye,
  "fridge": Fridge,
  "healthy-food": HealthyFoood,
  "dinner": Dinner,
  "fish": Fish,
  "bars": Bars,
  "caret-up": CaretUp,
  "caret-down": CaretDown,
  "trash": Trash,
  "clock": Clock,
  "music": Music,
  "check": Check,
  "hashtag": Hashtag,
  "seedling": Seedling,
  "instagram": Instagram,
  "facebook-square": FacebookSquare,
  "youtube": Youtube,
  "skipping-rope": SkippingRope,
  "swiss-ball": SwissBall,
  "kettlebell": Kettlebell,
  "box": Box,
  "med-ball": MedBall,
  "running-a-to-b": RunningAToB,
  "cosmopolitan": Cosmopolitan,
  "elle": Elle,
  "womens-health": WomensHealth,
  "popsugar": Popsugar,
  "facebook-circle": FacebookCircle,
  "boxing-glove": BoxingGlove,
  "shoe": Shoe,
  "droplets": Droplets,
  "cloud-upload": CloudUpload,
  "map-pin": MapPin,
  "eye": Eye,
  "calendar": Calendar,
  "lock": Lock,
  "pilates": Pilates,
  "sliders": Sliders,
  "globe": Globe,
  "copy": Copy,
  "yoga": Yoga,
  "ellipsis-v": EllipsisV,
  "download": Download,
  "heart-rate": HeartRate,
  "sync": Sync,
  "link": Link,
  "file-invoice-dollar": FileInvoiceDollar,
  "star-half": StarHalf,
  "star-full": StarFull,
  "whistle": Whistle,
  "bench": Bench,
  "yoga-block": YogaBlock,
  "resistance-band": ResistanceBand,
  "equipment-free": EquipmentFree,
  "drumstick-bite": DrumstickBite,
  "fish-alt": FishAlt,
  "cheese-swiss": CheeseSwiss,
  "pen": Pen,
  "foam-roller": FoamRoller,
  "pillow": Pillow,
  "pilates-ball": PilatesBall,
  "search": Search,
  "time": Time,
  "utensils-outline": UtensilsOutline,
  "email": Email,
  "arrow-right": ArrowRight,
  "app-store-button": AppStoreButton,
  "play-store-button": PlayStoreButton,
  "app-store-button-filled": AppStoreButtonFilled,
  "play-store-button-filled": PlayStoreButtonFilled,
  "ankle-weights": AnkleWeights,
  "mini-band": MiniBand,
  "pilates-chair": PilatesChair,
  "google": Google,
  "firebase": Firebase,
  "home": Home,
  "close": CloseIcon,
  "facebook": Facebook,
  "eye-hidden": EyeHidden,
  "caret-right-outline": CaretRightOutline,
  "caret-down-outline": CaretDownOutline,
  "caret-left-outline": CaretLeftOutline,
  "kic-logo": KicLogo,
  "user-outline": UserOutline,
  "android": AndroidIcon,
  "tick": Tick,
};

export const IconNames = Object.keys(Icons) as (keyof typeof Icons)[];

export type IconProps = {
  name: keyof typeof Icons;
  size?: ThemeValue<"iconSize", Theme>;
} & React.SVGProps<SVGSVGElement>;

const Icon: React.FC<IconProps> = ({
  name,
  size = "ism",
  color,
  className,
  ...rest
}) => {
  const theme = useTheme();
  const SelectedIcon = Icons[name] ?? EquipmentFree;

  const width = theme.iconSize[size];
  const height = theme.iconSize[size];
  const iconColor = theme.colors[color];

  return (
    <SelectedIcon
      fill={iconColor ?? theme.colors.contentContrastHigh}
      width={width ?? 40}
      height={height ?? 40}
      className={className}
      alt={name}
      {...rest}
    />
  );
};

export default Icon;
