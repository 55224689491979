import { CurrencyType, Discount, Plan } from "../../api";

const STRIPE_ZERO_DECIMAL_CURRENCIES: CurrencyType[] = [
  "BIF",
  "CLP",
  "DJF",
  "GNF",
  "JPY",
  "KMF",
  "KRW",
  "MGA",
  "PYG",
  "RWF",
  "UGX",
  "VND",
  "VUV",
  "XAF",
  "XOF",
  "XPF",
];
const getStripeDollarMultiplier = (currency: CurrencyType) => {
  return STRIPE_ZERO_DECIMAL_CURRENCIES.includes(currency) ? 1 : 100;
};
export const calculateFinalPrice = (
  plan: { price: number },
  currency: CurrencyType,
  discount?: Discount,
) => {
  if (!discount) return plan?.price;

  if (discount.unit === "flat") {
    return plan?.price - discount?.amount;
  } else if (discount.unit === "percentage") {
    const multiplier = getStripeDollarMultiplier(currency);
    const price =
      Math.floor(plan?.price * multiplier * (1 - discount?.amount / 100)) /
      multiplier;
    return price;
  }
};

export const formatFreeTrialLabel = ({
  plan,
  hyphenate = false,
}: {
  plan: Plan;
  hyphenate?: boolean;
}) => {
  if (!plan) return null;

  const plurality = !hyphenate && plan.trial_duration > 1 ? "s" : "";
  const hyphenation = hyphenate ? "-" : " ";
  return `${plan.trial_duration}${hyphenation}${plan.trial_duration_unit}${plurality}`;
};
