export const CancellationSurveyAnalyticEvents = {
  cancellation_pre_survey_viewed: "cancellation_pre_survey_viewed",
  cancellation_survey_started: "cancellation_survey_started",
  cancellation_survey_answer: "cancellation_survey_answer",
  cancellation_survey_completed: "cancellation_survey_completed",
  edit_subscription_clicked: "edit_subscription_clicked",
};

type CancellationSurveyEventProperties = {
  subscription_type: string;
  subscription_store: string;
};

export type CancellationSurveyAnalyticEvent =
  | ({
      eventName: "cancellation_pre_survey_viewed";
    } & CancellationSurveyEventProperties)
  | ({
      eventName: "cancellation_survey_started";
    } & CancellationSurveyEventProperties)
  | ({
      eventName: "cancellation_survey_answer";
    } & CancellationSurveyEventProperties & {
        question: string;
        answer: string;
      })
  | ({
      eventName: "cancellation_survey_completed";
    } & CancellationSurveyEventProperties & {
        answers: string[];
        feedback: string;
      })
  | ({
      eventName: "edit_subscription_clicked";
    } & CancellationSurveyEventProperties);
