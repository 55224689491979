import React, { useState } from "react";

import { Box, Button, ModalLegacy, Text } from "../../../../ui";
import { useAuth } from "../../../AuthWrapper/hooks";
import { useDeleteProfile } from "../../../../features/Users/hooks/useDeleteProfile";

type OnboardingNavModalProps = {
  isVisible: boolean;
  onModalClose: () => void;
};

export const OnboardingNavModal: React.FC<OnboardingNavModalProps> = ({
  isVisible,
  onModalClose,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { logout } = useAuth();
  const deleteProfile = useDeleteProfile();

  const onProfileDelete = () => deleteProfile.mutate();

  return (
    <Box
      css={{
        visibility: isVisible ? "visible" : "hidden",
        opacity: isVisible ? 1 : 0,
        transition: "ease 0.5s",
      }}
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      onClick={onModalClose}
    >
      <Box
        position="absolute"
        right={0}
        top={60}
        boxShadow="sm"
        p="sm"
        m="sm"
        backgroundColor="white"
        borderRadius="lg"
      >
        <Button
          px="sm"
          mb="xs"
          variant="buttonSmallFilledLight"
          text="Log out"
          onClick={logout}
        />
        <Button
          variant="buttonSmallFilledLight"
          text="Delete account"
          contentColor="contentNegative"
          onClick={() => setIsDeleteModalVisible(true)}
        />
        {isDeleteModalVisible && (
          <ModalLegacy
            isOpen={isDeleteModalVisible}
            close={() => setIsDeleteModalVisible(false)}
          >
            <Box
              borderRadius="lg"
              maxWidth={{ laptop: "60vw", tablet: "100vw", phone: "100vw" }}
              backgroundColor="white"
              p="md"
            >
              <Box mb="xs">
                <Text variant="hero">Delete Account?</Text>
              </Box>
              <Text variant="body">
                This action is permanent, your Kic account and all personal
                information will be deleted.
              </Text>
              <Box flexDirection="row" mt="md">
                <Button
                  mr="sm"
                  variant="buttonSmallFilledLight"
                  text="Cancel"
                  onClick={() => setIsDeleteModalVisible(false)}
                />
                <Button
                  variant="buttonSmallFilledLight"
                  text="Delete"
                  contentColor="contentNegative"
                  onClick={onProfileDelete}
                />
              </Box>
            </Box>
          </ModalLegacy>
        )}
      </Box>
    </Box>
  );
};
