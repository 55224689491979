import { actionOn, thunkOn, ThunkOn, ActionOn } from "easy-peasy";
import { StoreModel } from "..";

import * as monitoring from "../../src/utils/monitoring";
import initialState from "./profile.state";
import { EasyProfileModel } from "./profile.store";

export type ProfileListeners = {
  onLogIn: ThunkOn<EasyProfileModel, unknown, StoreModel>;
  onSignUp: ThunkOn<EasyProfileModel, unknown, StoreModel>;
  onLogOut: ThunkOn<EasyProfileModel, unknown, StoreModel>;
  onAllowTracking: ActionOn<EasyProfileModel, StoreModel>;
};

const listeners: ProfileListeners = {
  onLogIn: thunkOn<EasyProfileModel, unknown, StoreModel>(
    (actions, storeActions) => [
      storeActions.auth.logIn.successType,
      storeActions.auth.logInAuthCode.successType,
      storeActions.auth.logInFacebook.successType,
      storeActions.auth.logInApple.successType,
      storeActions.auth.logInGoogle.successType,
    ],
    async (actions, target) => {
      if (!target?.result?.error && !!target?.result?.user) {
        actions.updateUserData(target.result.user);

        await actions.updateUserProfile({
          attribution: await monitoring.getBrowserFingerprint(),
        });
      }
    },
  ),
  onSignUp: thunkOn<EasyProfileModel, unknown, StoreModel>(
    (actions, storeActions) => [storeActions.auth.signUp.successType],
    async (actions, target) => {
      if (!target?.result?.error && !!target?.result?.user) {
        actions.updateUserData(target.result.user);

        const { agreedToCommunications, firstName, lastName } = target.payload;

        await actions.updateUserProfile({
          first_name: firstName,
          last_name: lastName,
          agreed_communications: agreedToCommunications,
          attribution: await monitoring.getBrowserFingerprint(),
        });
      }
    },
  ),
  onLogOut: thunkOn<EasyProfileModel, unknown, StoreModel>(
    (actions, storeActions) => storeActions.auth.logOut,
    (actions) => {
      actions.setUserData(initialState.userData);
      monitoring.onLogout();
    },
  ),
  onAllowTracking: actionOn<EasyProfileModel, StoreModel, any>(
    (actions) => actions.setAllowTracking,
    (state, target) => {
      const { payload: allowTracking } = target;

      monitoring.initialise(allowTracking, state.userData);
    },
  ),
};

export default listeners;
