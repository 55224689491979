import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M13.6868 4.31313C13.8821 4.5084 13.8821 4.82498 13.6868 5.02024L7.02014 11.6869C6.82488 11.8822 6.50829 11.8822 6.31303 11.6869L2.9797 8.35357C2.78444 8.15831 2.78444 7.84173 2.9797 7.64647C3.17496 7.4512 3.49154 7.4512 3.68681 7.64647L6.66659 10.6262L12.9797 4.31313C13.175 4.11787 13.4915 4.11787 13.6868 4.31313Z"
        fill="#212120"
      />
    </svg>
  );
}

export default SvgComponent;
