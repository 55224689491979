import React, { useRef } from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";

import { Box } from "../Box";
import { Text } from "../Text/Text";
import { _TextArea, makeErrorTextAreaStyle } from "./BaseTextArea";

type TextInputProps = {
  placeholder: string;
  onRegister?: UseFormRegister<FieldValues>;
  inputFieldName?: string;
  error?: string;
  disabled?: boolean;
  rows?: number;
  characterLimit?: number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
};

export const TextArea: React.FC<TextInputProps> = ({
  onRegister,
  inputFieldName,
  error,
  rows = 5,
  characterLimit,
  disabled = false,
  onChange,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <Box>
      <_TextArea
        ref={textAreaRef}
        css={error ? makeErrorTextAreaStyle : null}
        disabled={disabled}
        maxLength={characterLimit}
        rows={rows}
        onChange={onChange}
        {...(onRegister ? onRegister(inputFieldName) : {})}
        {...rest}
      />
      {!!error && (
        <Box ml="sm" mt="xxs">
          <Text variant="buttonSmall" color="contentNegative">
            {error}
          </Text>
        </Box>
      )}
      {characterLimit && (
        <Box px="sm" py="xxs">
          <Text variant={"bodySmall"} color={"contentPositive"}>
            {characterLimit - (textAreaRef.current?.value.length ?? 0)}
          </Text>
        </Box>
      )}
    </Box>
  );
};
