import React from "react";
import { Box } from "../Box/Box";
import { Text } from "../Text/Text";

type TileContentProps = {
  title: string;
  subtitle?: string;
  itemIsFocused?: boolean;
};

export const TileContent: React.FC<TileContentProps> = (props) => {
  return (
    <Box m="xs">
      <Text variant="subtitle" maxLines={props.itemIsFocused ? 0 : 1}>
        {props.title}
      </Text>
      {props.subtitle && (
        <Text variant="bodySmall" maxLines={1}>
          {props.subtitle}
        </Text>
      )}
    </Box>
  );
};
