import React from "react";

import { StyledModal, Container } from "./styles";

export interface InnerModalProps {
  isOpen: boolean;
  close: () => void;
}

const InnerModal: React.FC<InnerModalProps> = ({ isOpen, close, children }) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={close}
      shouldCloseOnOverlayClick
      closeTimeoutMS={500}
    >
      <Container>{children}</Container>
    </StyledModal>
  );
};

export const ModalLegacy: React.FC<InnerModalProps> = React.memo(InnerModal);
